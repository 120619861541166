import React from "react"
import { DocumentDownloadIcon, DocumentIcon } from "@heroicons/react/outline"
// import { Link } from "gatsby"

import Header from "./home/_header"

async function handleFormSubmit(event) {
  event.preventDefault()

  const formData = new FormData(event.target)
  //   const files = event.target.files
  //   formData.append("files", files)

  console.log(formData)

  await fetch("http://localhost:5000/upload", {
    method: "POST",
    headers: {
      Accept: "*/*",
    },
    body: formData,
  }).then(res => res.json())
}

function Resources(props) {
  const pathname = props.location.pathname
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/*  Site header */}
      <Header pathname={pathname} />

      {/*  Page content */}
      <main className="flex-grow">
        <section className="bg-gradient-to-b from-gray-100 to-white">
          <div className="max-w-6xl mx-auto px-4 sm:px-6">
            <div className="pt-32 pb-12 md:pt-40 md:pb-20">
              {/* Page header */}
              <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
                <h1 className="h1">Resources</h1>
              </div>
              <div className="max-w-3xl mx-auto text-center mb-6">
                <h2 className="h3">Download form below</h2>
              </div>
              <div className="flex justify-center mb-20">
                <a
                  className="flex justify-center items-center hover:underline"
                  href="/forms/form-a.xlsx"
                  target="_blank"
                >
                  <DocumentDownloadIcon className="h-8 w-8 text-blue-600 mr-2" />
                  <p className="text-lg">Application form</p>
                </a>
              </div>
              <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
                <h2 className="h3">Postal address</h2>
                <div className="flex justify-center my-6">
                  <p>
                    Richard Kiew & Company
                    <br />
                    P.O. Box 2536
                    <br />
                    93750 Kuching
                    <br />
                    Sarawak, Malaysia
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  )
}

export default Resources
